.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


:global(#hero) {
  height: 690px;
  max-height: 690px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1023px) {
    max-height: 717px;
    height: 717px;
  }
  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    & > div {
      padding: 0px;
      width: 100%;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 60px;
    font-weight: 800;
    line-height: 90px;
    letter-spacing: 0.06em;
    text-align: center;
    color: #fff;
    padding: 0;
    @media (max-width: 1023px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 42px;
    }
    & strong {
      font-family: Lobster;
      font-size: 80px;
      font-weight: 400;
      line-height: 100px;
      letter-spacing: 0.06em;
      text-align: center;
      text-shadow: 3px 0px 0px #e27757;
      @media (max-width: 1023px) {
        font-size: 42px;
        line-height: 52px;
      }
      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 45px;
      }
    }
  }
  & :global(#chair_hero_block) {
    position: absolute;
    top: 171px;
    right: 10%;
    @media (max-width: 1300px) {
        right: 2%;
    }
    @media (max-width: 1023px) {
        top: unset;
        right: 122px;
        bottom: 123px;
    }
    &>div{
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background: #E0D687;
            border-radius: 100px;
            top: 3px;
            left: -21px;
        }
    }
    & p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 900;
      line-height: 19.36px;
      text-align: left;
      margin: 0;
      padding: 0;
      @media (max-width: 1023px) {
        color: #2B3740;
      }
    }
  }
  & :global(#boots_hero_block) {
    position: absolute;
    bottom: 96px;
    right: 25%;
    @media (max-width: 1300px) {
        right: 18%;
    }
    @media (max-width: 1023px) {
       display: none;
    }
    &>div{
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background: #E0D687;
            border-radius: 100px;
            top: 3px;
            left: -21px;
        }
    }
    & p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 900;
      line-height: 19.36px;
      text-align: left;
      margin: 0;
      padding: 0;
      @media (max-width: 1023px) {
        color: #2B3740;
      }
    }
  }
  & :global(#desk_hero_block) {
    position: absolute;
    bottom: 134px;
    left: 15%;
    @media (max-width: 1300px) {
        left: 4%;
    }
    @media (max-width: 1023px) {
        display: none;
    }
    &>div{
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background: #E0D687;
            border-radius: 100px;
            top: 3px;
            left: -21px;
        }
    }
    & p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 900;
      line-height: 19.36px;
      text-align: left;
      margin: 0;
      padding: 0;
      @media (max-width: 1023px) {
        color: #2B3740;
      }
    }
  }
  & :global(#art_hero_block) {
    position: absolute;
    top: 214px;
    left: 11%;
    @media (max-width: 1300px) {
        left: 2%;
    }
    @media (max-width: 1023px) {
        top: 85px;
        left: 38%;
    }
    &>div{
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background: #E0D687;
            border-radius: 100px;
            top: 3px;
            left: -21px;
        }
    }
    & p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 900;
      line-height: 19.36px;
      text-align: left;
      margin: 0;
      padding: 0;
      @media (max-width: 1023px) {
        color: #2B3740;
      }
    }
  }
  & :global(#hero-block-1) {
   flex-direction: unset;
  }
}
/* :global(#locations) {
  & h2 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -1.5px;
    text-align: center;
    color: #2b3740;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 44px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.75px;
    text-align: left;
    color: #2b3740;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  & > div {
    padding: 37px 0 101px;
    @media (max-width: 767px) {
      padding: 36px 0 80px;
    }
    & > div {
      padding: 51px 0 0;
      @media (max-width: 767px) {
        padding: 36px 0 0;
      }
    }
  }
  & :global(#locations-block-1),
  & :global(#locations-block-2),
  & :global(#locations-block-3),
  & :global(#locations-block-4),
  & :global(#locations-block-5) {
    @media (min-width: 768px) {
      width: calc((min(1344px, var(--carouselWidth)) - 64px - 96px) / 4);
    }
    & > div {
      &:nth-child(2) {
        margin-top: 16px;
      }
      & > div {
        & > div {
          padding-bottom: 100% !important;
          & img {
            border-radius: 4px;
            @media (min-width: 1400px) {
              width: 296px;
            }
          }
        }
      }
    }
  }
  & :global(#locations-slider) {
    padding-bottom: 0;
  }
} */
:global(#how_work) {
  @media (min-width: 1024px) {
    padding: 0 36px 36px;
  }
  & > div {
    padding: 315px 0 109px;
    position: relative;
    background-color: #e3c0ac;
    & > div {
      max-width: calc(1283px + 64px);
    }
  }
  & img {
    border-radius: 4px;
  }
  & h3 {
    font-family: Inter;
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.75px;
    text-align: left;
    padding: 7px 0 4px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
      padding: 0 0 4px;
    }
  }
  & p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.34823527932167053px;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  & :global(#title_block) {
    position: absolute;
    top: 85px;
    text-align: center;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
      top: 48px;
    }
    & > div {
      width: unset;
    }
    & p {
      margin: 0 auto;
      padding: 0;
      text-align: center;
      @media (max-width: 1023px) {
        padding: 0 16px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        max-width: 661px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.34823527932167053px;
        text-align: center;
        color: #2B3740;
        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      &:nth-child(1) {
        max-width: 930px;
        margin-bottom: 36px;
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }
    }
    & em {
      font-family: Poppins;
      font-size: 50px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -1.5px;
      text-align: center;
      color: #2B3740;
      @media (max-width: 1023px) {
        font-size: 36px;
        line-height: 45px;
      }
      & strong {
        font-family: Lobster;
        font-size: 65px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: -1.5px;
        text-align: center;
        @media (max-width: 1023px) {
          font-size: 36px;
          line-height: 45px;
        }
      }
    }
  }
}
