@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global(#locations){
  & p{
   @media(max-width: 768px){
    font-size: 14px;
   }
  }
}
:global(#how-it-works-for-owners){
  & p{
    @media(max-width: 768px){
      font-size: 14px;
     }
  }
}
:global(#how-it-works-for-customers){
  & p{
    @media(max-width: 768px){
      font-size: 14px;
     }
  }
}
:global(#own-home-section){
  & p{
    @media(max-width: 768px){
      font-size: 14px;
     }
  }
}