@import '../../../../styles/customMediaQueries.css';

.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.searchInputs {
  display: flex;
  align-items: center;
  margin: 12px 20px !important;
  @media (min-width: 1024px) {
    gap: 0;
    background: #fff;
    box-shadow: 4px 4px 0px 0px #e27757;
    border-radius: 8px;
    padding: 11px 0;
    max-width: 918px;
    margin: 12px auto 48px !important;
    position: relative;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
  & > div {
    &:nth-child(2) {
      max-width: 380px;
      & > div {
        &:before {
          @media (min-width: 1024px) {
          right: 20px;
          top: 21px;
          }
      
        }
        &:after {
          @media (min-width: 1024px) {
          left: 34px;
          top: 13px;
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 11.5C9.61875 11.5 10.1486 11.2746 10.5896 10.8238C11.0299 10.3738 11.25 9.8325 11.25 9.2C11.25 8.5675 11.0299 8.02585 10.5896 7.57505C10.1486 7.12502 9.61875 6.9 9 6.9C8.38125 6.9 7.85175 7.12502 7.4115 7.57505C6.9705 8.02585 6.75 8.5675 6.75 9.2C6.75 9.8325 6.9705 10.3738 7.4115 10.8238C7.85175 11.2746 8.38125 11.5 9 11.5ZM9 19.9525C11.2875 17.8058 12.9844 15.8554 14.0906 14.1013C15.1969 12.3479 15.75 10.7908 15.75 9.43C15.75 7.34083 15.0982 5.63002 13.7947 4.29755C12.492 2.96585 10.8938 2.3 9 2.3C7.10625 2.3 5.50763 2.96585 4.20412 4.29755C2.90138 5.63002 2.25 7.34083 2.25 9.43C2.25 10.7908 2.80312 12.3479 3.90937 14.1013C5.01562 15.8554 6.7125 17.8058 9 19.9525ZM9 23C5.98125 20.3742 3.72675 17.935 2.2365 15.6825C0.7455 13.4309 0 11.3467 0 9.43C0 6.555 0.904875 4.26458 2.71463 2.55875C4.52363 0.852917 6.61875 0 9 0C11.3812 0 13.4764 0.852917 15.2854 2.55875C17.0951 4.26458 18 6.555 18 9.43C18 11.3467 17.2549 13.4309 15.7646 15.6825C14.2736 17.935 12.0188 20.3742 9 23Z' fill='%23E27757'/%3E%3C/svg%3E%0A");
        }}
        & input {
          background: #fff;
          @media (min-width: 1024px) {
            padding-left: 75px;
          }
        }
      }
    }
    &:nth-child(1) {
      max-width: 538px;
      width: 100%;
      & > div > div > div {
        @media (min-width: 1024px) {
          top: 2px;
        }
      }
      & > div {
        &>div{
          &:nth-child(2){
            @media (min-width: 1024px) {
              top: 64px;
            }
          }
        }
        &:after {
          @media (min-width: 1024px) {
            left: 34px;
            top: 13px;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 11.5C9.61875 11.5 10.1486 11.2746 10.5896 10.8238C11.0299 10.3738 11.25 9.8325 11.25 9.2C11.25 8.5675 11.0299 8.02585 10.5896 7.57505C10.1486 7.12502 9.61875 6.9 9 6.9C8.38125 6.9 7.85175 7.12502 7.4115 7.57505C6.9705 8.02585 6.75 8.5675 6.75 9.2C6.75 9.8325 6.9705 10.3738 7.4115 10.8238C7.85175 11.2746 8.38125 11.5 9 11.5ZM9 19.9525C11.2875 17.8058 12.9844 15.8554 14.0906 14.1013C15.1969 12.3479 15.75 10.7908 15.75 9.43C15.75 7.34083 15.0982 5.63002 13.7947 4.29755C12.492 2.96585 10.8938 2.3 9 2.3C7.10625 2.3 5.50763 2.96585 4.20412 4.29755C2.90138 5.63002 2.25 7.34083 2.25 9.43C2.25 10.7908 2.80312 12.3479 3.90937 14.1013C5.01562 15.8554 6.7125 17.8058 9 19.9525ZM9 23C5.98125 20.3742 3.72675 17.935 2.2365 15.6825C0.7455 13.4309 0 11.3467 0 9.43C0 6.555 0.904875 4.26458 2.71463 2.55875C4.52363 0.852917 6.61875 0 9 0C11.3812 0 13.4764 0.852917 15.2854 2.55875C17.0951 4.26458 18 6.555 18 9.43C18 11.3467 17.2549 13.4309 15.7646 15.6825C14.2736 17.935 12.0188 20.3742 9 23Z' fill='%23E27757'/%3E%3C/svg%3E%0A");
          }
        }
        & input {
          @media (min-width: 1024px) {
            background-position: 47px 15px;
            padding-left: 99px;
          }
        }
      }
    }
    & > div {
      & > input {
        @media (min-width: 1024px) {
          border-left: 3px solid #e3c0ac;
        }
      }
    }
  }
  & input {
    @media (min-width: 1024px) {
      box-shadow: none;
      border-radius: 0;
      border: none;
      height: 48px;
    }
  }
}

.clearKeywords {
  border: none;
  position: absolute;
  top: 10px;
  right: 70px;
  cursor: pointer;
  &:hover{
    background-color: #f4e8e1;
  }
}
.algoliaLocation{
  button{
    top: 12px;
  }
}

.micClass{
  top: 6px!important;
  @media (--viewportMedium){
    top: -1px!important;
  }
}